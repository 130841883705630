"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  weight: 125,
  key: 'validate.minWords',
  label: 'Minimum Word Length',
  placeholder: 'Minimum Word Length',
  type: 'number',
  tooltip: 'The minimum amount of words that can be added to this field.',
  input: true
}, {
  weight: 126,
  key: 'validate.maxWords',
  label: 'Maximum Word Length',
  placeholder: 'Maximum Word Length',
  type: 'number',
  tooltip: 'The maximum amount of words that can be added to this field.',
  input: true
}];
exports["default"] = _default;