<h1 mat-dialog-title>
  {{ 'Profile Setup' | translate }}
  <cl-functional-button
    (click)="dialogRef.close(false)"
    [icon]="Icon.cancel"></cl-functional-button>
</h1>
<div mat-dialog-content>
  <span class="desc">{{ 'To begin, please select your role below:' | translate }}</span>
  <div class="role-select">
    <cl-navigation-button
      [desc]="
        'For parents and guardians who will enroll their children in Waterford Upstart.' | translate
      "
      [disabled]="false"
      [icon]="Icon.families"
      [title]="'Family' | translate"
      [buttonLabel]="'Select' | translate"
      [buttonLabelSelected]="'Selected' | translate"
      [buttonIconSelected]="Icon.checkmark"
      [selected]="isSelected(UpstartAccountType.FAMILY)"
      (click)="selectUpstartAccountType(UpstartAccountType.FAMILY)"></cl-navigation-button>
    <cl-navigation-button
      [desc]="
        'For child care centers who will use Waterford Upstart with children in their facilities.'
          | translate
      "
      [disabled]="false"
      [icon]="Icon.numberBlocks"
      [title]="'Child Care Center' | translate"
      [buttonLabel]="'Select' | translate"
      [buttonLabelSelected]="'Selected' | translate"
      [buttonIconSelected]="Icon.checkmark"
      [selected]="isSelected(UpstartAccountType.CARE_CENTER)"
      (click)="selectUpstartAccountType(UpstartAccountType.CARE_CENTER)"></cl-navigation-button>
  </div>
</div>

<div mat-dialog-actions>
  <cl-button
    buttonStyle="primary"
    [disabled]="!this.selectedUpstartAccountType"
    (click)="setUpstartAccountType()">
    <span>Submit</span>
  </cl-button>
</div>
