import { Injectable } from '@angular/core';
import { ApiGatewayService, authenticationSelectors } from '@wam/authentication';
import { select, Store } from '@ngrx/store';
import { State, User } from '@wam/shared';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ProgramsClaim } from './state/programs-claims.model';
import { some } from 'lodash-es';
import { FormInheritedFields } from '@wap/enrollment-v2';

@Injectable({ providedIn: 'root' })
export class ProgramsClaimsService {
  constructor(
    private apiGatewayService: ApiGatewayService,
    private store: Store<State>,
  ) {}

  getSubmissionsByFormId(formId: string): Observable<ProgramsClaim[]> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.get<ProgramsClaim[]>(
          `enrollment-v2/v2/apps/${user.application}/orgs/upstart/forms/${formId}/submissions`,
        ),
      ),
      take(1),
    );
  }

  getInheritedFieldsFromAuth(
    inheritedFields: FormInheritedFields['AUTH'],
    user: User,
  ): Record<string, string> {
    const authInheritedFields = inheritedFields;
    const fields = {};
    if (
      some(authInheritedFields, { targetField: 'submitterEmailFieldHidden', sourceField: 'email' })
    ) {
      if (!user.email.match(/^.*@privaterelay.appleid.com$/)) {
        fields['submitterEmailFieldHidden'] = user.email;
      }
    }
    return fields;
  }

  private withUser(): Observable<User> {
    return this.store.pipe(select(authenticationSelectors.getCurrentUser));
  }
}
