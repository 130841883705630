import { Injectable } from '@angular/core';
import { ApiGatewayService, authenticationSelectors } from '@wam/authentication';
import { Observable, of } from 'rxjs';
import { settingsSelectors, State, User } from '@wam/shared';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
  EnrollmentType,
  Form,
  ProgramEnrollment,
  ProgramEnrollmentStatus,
} from '@wap/enrollment-v2';
import { HttpClient } from '@angular/common/http';
import {
  FormSubmission,
  ProgramsClaim,
} from '@app/programs/programs-claims/state/programs-claims.model';
import * as fromProgramsClaims from '@app/programs/programs-claims/state/programs-claims.selectors';

interface SubmitEnrollmentFormParams {
  programId?: string;
  formId?: string;
  formVersion?: string;
  state?: string;
  stepId?: string;
  subStepId?: string;
  submission?: object;
  submissionId?: string;
  enrollmentType?: EnrollmentType;
  subStepStatus?: ProgramEnrollmentStatus;
}

@Injectable({
  providedIn: 'root',
})
export class UpstartIntakeDataService {
  constructor(
    private apiGatewayService: ApiGatewayService,
    private store: Store<State>,
    private http: HttpClient,
  ) {}

  getFormById(formId: string): Observable<Form> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.simpleGet<{ data: { attributes: Form } }>(
          `enrollment-v2/v2/apps/${user.application}/forms/${formId}`,
        ),
      ),
      map((response) => response.data.attributes),
      take(1),
    );
  }

  getEnrollmentWizard(): Observable<Form> {
    return this.store.pipe(select(settingsSelectors.getApiSettings)).pipe(
      switchMap(({ key, url }) =>
        this.http.get(
          `https://${url}/enrollment-v2/v2/apps/waterford-parent/forms/enrollmentWizard`,
          {
            headers: {
              'x-api-key': key,
            },
          },
        ),
      ),
      map((response: { data: { attributes: Form } }) => response.data.attributes),
      take(1),
    );
  }

  validateZip(zip: string): Observable<string> {
    return this.store.pipe(select(settingsSelectors.getApiSettings)).pipe(
      switchMap(({ key, url }) =>
        this.http.get<string>(
          `https://${url}/enrollment-v2/v2/apps/waterford-parent/zip/${zip}/stateCode`,
          {
            headers: {
              'x-api-key': key,
            },
          },
        ),
      ),
      catchError(() => of(null)),
      take(1),
    );
  }

  getProgramsCount(params): Observable<number> {
    return this.store.pipe(select(settingsSelectors.getApiSettings)).pipe(
      switchMap(({ key, url }) =>
        this.http.get<{ data: { attributes: { count: number } } }>(
          `https://${url}/enrollment-v2/v2/apps/waterford-parent/programs/count?`,
          {
            headers: {
              'x-api-key': key,
            },
            params,
          },
        ),
      ),
      map((response) => response.data.attributes.count),
      catchError(() => of(null)),
      take(1),
    );
  }

  updateFormSubmission<T = FormSubmission>(
    formId: string,
    programsClaim: ProgramsClaim<T>,
    newSubmission,
  ): Observable<ProgramsClaim<T>> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.put<ProgramsClaim<T>>(
          `enrollment-v2/v2/apps/${user.application}/orgs/upstart/forms/${formId}/versions/${programsClaim.formVersion}/submissions/${programsClaim.id}`,
          { formSubmission: newSubmission },
        ),
      ),
      take(1),
    );
  }

  submitForm(form: Form, formSubmission: object): Observable<ProgramsClaim> {
    return this.withUser().pipe(
      switchMap((user: User) =>
        this.apiGatewayService.post<ProgramsClaim>(
          `enrollment-v2/v2/apps/${user.application}/orgs/upstart/forms/${form.id}/versions/${form.version}/submissions`,
          { formSubmission },
        ),
      ),
      take(1),
    );
  }

  submitEnrollmentForm(params: SubmitEnrollmentFormParams): Observable<ProgramEnrollment> {
    const {
      programId,
      formId,
      formVersion,
      state,
      stepId,
      subStepId,
      submission,
      submissionId,
      enrollmentType,
      subStepStatus = ProgramEnrollmentStatus.COMPLETED,
    } = params;

    return this.withUser().pipe(
      withLatestFrom(this.store.pipe(select(fromProgramsClaims.getSelectedProgramsClaim))),
      switchMap(([{ application }, { id }]) =>
        this.apiGatewayService.post<ProgramEnrollment>(
          `enrollment-v2/v2/apps/${application}/orgs/upstart/forms/${formId}/states/${state}/programs/${programId}/programEnrollments/${id}${
            formVersion ? `?formVersion=${formVersion}` : ''
          }`,
          { stepId, subStepId, submission, subStepStatus, submissionId, enrollmentType },
        ),
      ),
      take(1),
    );
  }

  private withUser(): Observable<User> {
    return this.store.pipe(select(authenticationSelectors.getCurrentUser));
  }
}
