import { Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icon } from '@wam/icons';
import { Form, FormInheritedFields } from '@wap/enrollment-v2';
import { combineLatest, forkJoin, map, mergeMap, take } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { configurationSelectors, settingsSelectors } from '@wam/shared';
import { UpstartIntakeDataService } from '@app/shared/upstart-intake-data.service';
import { DynamicLoaderService } from '@app/shared/dynamic-loader.service';
import { FacilityInfo, ProgramsClaim } from '../../programs-claims/state/programs-claims.model';
import { ProgramsClaimsService } from '../../programs-claims/programs-claims.service';
import { PROVIDER_FORM_ID } from '@app/constants';
import { authenticationSelectors } from '@wam/authentication';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'enrollment-facility-dialog',
  templateUrl: './enrollment-facility-dialog.component.html',
  styleUrls: ['./enrollment-facility-dialog.component.scss'],
})
export class EnrollmentFacilityDialogComponent implements OnInit {
  Icon = Icon;

  @ViewChild('formElement') formElement: ElementRef<HTMLDivElement>;
  refreshForm: EventEmitter<object>;
  renderOptions: {
    language: string;
    i18n: object;
    icons: string;
  };
  formWizard: Form;
  isLoading = true;
  selectedFacilityInfo?: ProgramsClaim<FacilityInfo>;
  inheritedFields: FormInheritedFields;

  get editMode(): boolean {
    return this.data.editMode;
  }

  constructor(
    private upstartIntakeDataService: UpstartIntakeDataService,
    private dynamicLoaderService: DynamicLoaderService,
    private programsClaimsService: ProgramsClaimsService,
    private store: Store,
    public dialogRef: MatDialogRef<EnrollmentFacilityDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private data: { editMode: boolean },
  ) {}

  ngOnInit(): void {
    this.refreshForm = new EventEmitter();
    const dynamicLoader$ = this.store.pipe(
      select(settingsSelectors.getUpstartRegistrationSettings),
      mergeMap((upstartRegistrationSettings) =>
        this.dynamicLoaderService.loadCss(upstartRegistrationSettings.programsCss).pipe(take(1)),
      ),
    );
    const form$ = this.upstartIntakeDataService
      .getFormById(PROVIDER_FORM_ID)
      .pipe(
        mergeMap((form: Form) =>
          forkJoin(form.jsResources.map((url) => this.dynamicLoaderService.loadScript(url))).pipe(
            map(() => form),
          ),
        ),
      );

    combineLatest([
      dynamicLoader$,
      form$,
      this.programsClaimsService.getSubmissionsByFormId(PROVIDER_FORM_ID),
      this.store.select(configurationSelectors.getUserConfiguration<string>('language')),
    ])
      .pipe(
        map(([_, formWizard, selectedFacilityInfo, language]) => {
          this.initializeData(formWizard, selectedFacilityInfo, language);
        }),
        take(1),
      )
      .subscribe();
  }

  loadFormSubmission(): void {
    this.store
      .select(authenticationSelectors.getCurrentUser)
      .pipe(
        take(1),
        tap((user) => {
          this.refreshForm.emit({
            submission: {
              data: {
                ...this.programsClaimsService.getInheritedFieldsFromAuth(
                  this.inheritedFields?.['AUTH'] ?? [],
                  user,
                ),
                ...this.selectedFacilityInfo?.formSubmission,
              },
            },
          });
        }),
      )
      .subscribe();
  }

  submit(submission): void {
    if (this.data.editMode && this.selectedFacilityInfo) {
      this.upstartIntakeDataService
        .updateFormSubmission<FacilityInfo>(
          this.formWizard.id,
          this.selectedFacilityInfo,
          submission.data,
        )
        .pipe(take(1))
        .subscribe();
    } else {
      this.upstartIntakeDataService
        .submitForm(this.formWizard, submission.data)
        .pipe(take(1))
        .subscribe();
    }
  }

  closeDialog(dialogResult): void {
    this.dialogRef.close(dialogResult);
  }

  private initializeData(formWizard, selectedFacilityInfo, language): void {
    this.selectedFacilityInfo = selectedFacilityInfo[0];
    this.formWizard = formWizard;
    this.inheritedFields = formWizard.inheritedFields;
    this.isLoading = false;
    this.renderOptions = {
      language: 'en',
      i18n: {
        en: this.formWizard.layout.i18n.en,
      },
      icons: 'waterford-wizard-icons',
    };
    this.formElement.nativeElement.setAttribute('data-form-lang', language);
    this.refreshForm.emit({
      submission: {
        data: {},
      },
    });
  }
}
