exports.defaults = {};
exports.set = function (name, value, options) {
  // Retrieve options and defaults
  var opts = options || {};
  var defaults = exports.defaults;

  // Apply default value for unspecified options
  var expires = opts.expires || defaults.expires;
  var domain = opts.domain || defaults.domain;
  var path = opts.path !== undefined ? opts.path : defaults.path !== undefined ? defaults.path : '/';
  var secure = opts.secure !== undefined ? opts.secure : defaults.secure;
  var httponly = opts.httponly !== undefined ? opts.httponly : defaults.httponly;
  var samesite = opts.samesite !== undefined ? opts.samesite : defaults.samesite;

  // Determine cookie expiration date
  // If succesful the result will be a valid Date, otherwise it will be an invalid Date or false(ish)
  var expDate = expires ? new Date(
  // in case expires is an integer, it should specify the number of days till the cookie expires
  typeof expires === 'number' ? new Date().getTime() + expires * 864e5 :
  // else expires should be either a Date object or in a format recognized by Date.parse()
  expires) : 0;

  // Set cookie
  document.cookie = name.replace(/[^+#$&^`|]/g, encodeURIComponent) // Encode cookie name
  .replace('(', '%28').replace(')', '%29') + '=' + value.replace(/[^+#$&/:<-\[\]-}]/g, encodeURIComponent) + (
  // Encode cookie value (RFC6265)
  expDate && expDate.getTime() >= 0 ? ';expires=' + expDate.toUTCString() : '') + (
  // Add expiration date
  domain ? ';domain=' + domain : '') + (
  // Add domain
  path ? ';path=' + path : '') + (
  // Add path
  secure ? ';secure' : '') + (
  // Add secure option
  httponly ? ';httponly' : '') + (
  // Add httponly option
  samesite ? ';samesite=' + samesite : ''); // Add samesite option
};
exports.get = function (name) {
  var cookies = document.cookie.split(';');

  // Iterate all cookies
  while (cookies.length) {
    var cookie = cookies.pop();

    // Determine separator index ("name=value")
    var separatorIndex = cookie.indexOf('=');

    // IE<11 emits the equal sign when the cookie value is empty
    separatorIndex = separatorIndex < 0 ? cookie.length : separatorIndex;
    var cookie_name = decodeURIComponent(cookie.slice(0, separatorIndex).replace(/^\s+/, ''));

    // Return cookie value if the name matches
    if (cookie_name === name) {
      return decodeURIComponent(cookie.slice(separatorIndex + 1));
    }
  }

  // Return `null` as the cookie was not found
  return null;
};
exports.erase = function (name, options) {
  exports.set(name, '', {
    expires: -1,
    domain: options && options.domain,
    path: options && options.path,
    secure: 0,
    httponly: 0
  });
};
exports.all = function () {
  var all = {};
  var cookies = document.cookie.split(';');

  // Iterate all cookies
  while (cookies.length) {
    var cookie = cookies.pop();

    // Determine separator index ("name=value")
    var separatorIndex = cookie.indexOf('=');

    // IE<11 emits the equal sign when the cookie value is empty
    separatorIndex = separatorIndex < 0 ? cookie.length : separatorIndex;

    // add the cookie name and value to the `all` object
    var cookie_name = decodeURIComponent(cookie.slice(0, separatorIndex).replace(/^\s+/, ''));
    all[cookie_name] = decodeURIComponent(cookie.slice(separatorIndex + 1));
  }
  return all;
};