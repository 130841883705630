import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Icon } from '@wam/icons';
import { ProgramsClaim } from '../../../programs-claims/state/programs-claims.model';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, forkJoin, map, mergeMap, take } from 'rxjs';
import { UpstartIntakeDataService } from '@app/shared/upstart-intake-data.service';
import { DynamicLoaderService } from '@app/shared/dynamic-loader.service';
import { NotificationType, NotificationsService } from '@wam/notifications';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { configurationSelectors, settingsSelectors } from '@wam/shared';
import { Form } from '@wap/enrollment-v2';
import { isNil } from 'lodash-es';
import * as fromProgramsClaims from '@app/programs/programs-claims/state/programs-claims.selectors';
import * as programsClaimsActions from '@app/programs/programs-claims/state/programs-claims.actions';

@Component({
  selector: 'enrollment-child-form',
  templateUrl: './enrollment-child-form.component.html',
  styleUrls: ['./enrollment-child-form.component.scss'],
})
export class EnrollmentChildFormComponent implements OnInit {
  Icon = Icon;
  private readonly CHILD_FORM_ID = 'INTKWIZ';
  @Input() editMode = false;
  @Output() submitted = new EventEmitter<void>();
  @ViewChild('formElement') formElement: ElementRef<HTMLDivElement>;
  refreshForm = new EventEmitter<object>();
  renderOptions: {
    language: string;
    i18n: object;
    icons: string;
  };
  formWizard: Form;
  isLoading = true;
  selectedClaim: ProgramsClaim;
  programsClaims: ProgramsClaim[];
  googleAnalyticsEnabled$: Observable<boolean> = this.store.pipe(
    select(settingsSelectors.isGoogleAnalyticsEnabled),
  );

  constructor(
    private upstartIntakeDataService: UpstartIntakeDataService,
    private dynamicLoaderService: DynamicLoaderService,
    private store: Store,
    private gtmService: GoogleTagManagerService,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.store.pipe(
        select(settingsSelectors.getUpstartRegistrationSettings),
        mergeMap((upstartRegistrationSettings) =>
          this.dynamicLoaderService.loadCss(upstartRegistrationSettings.programsCss).pipe(take(1)),
        ),
      ),
      this.upstartIntakeDataService
        .getFormById(this.CHILD_FORM_ID)
        .pipe(
          mergeMap((form: Form) =>
            forkJoin(form.jsResources.map((url) => this.dynamicLoaderService.loadScript(url))).pipe(
              map(() => form),
            ),
          ),
        ),
      this.store.select(fromProgramsClaims.getProgramsClaims),
      this.store.select(fromProgramsClaims.getSelectedProgramsClaim),
      this.store.select(configurationSelectors.getUserConfiguration<string>('language')),
    ])
      .pipe(
        map(([_, formWizard, programsClaims, selectedProgramsClaim, language]) => {
          this.initializeData(formWizard, programsClaims, selectedProgramsClaim, language);
        }),
        take(1),
      )
      .subscribe();
  }

  loadFormSubmission(): void {
    if (this.editMode) {
      this.refreshForm.emit({
        submission: {
          data: {
            ...this.selectedClaim.formSubmission,
          },
        },
      });
    }
  }

  submit(submission): void {
    submission.data = {
      ...submission.data,
      childLabel: `${submission.data.programChildFirstNameField} ${submission.data.programChildLastNameField}`,
    };
    if (this.thereExistsADifferentSubmissionWithSameChildLabel(submission.data.childLabel)) {
      this.notificationsService.notify(
        NotificationType.ERROR,
        'There already exists an entry with this data.',
      );
      this.submitted.emit();
    } else {
      if (this.editMode) {
        this.upstartIntakeDataService
          .updateFormSubmission(this.formWizard.id, this.selectedClaim, submission.data)
          .pipe(take(1))
          .subscribe((programsClaim) => {
            this.store.dispatch(
              programsClaimsActions.updateSelectedProgramsClaimData({ programsClaim }),
            );
          });
        this.submitted.emit();
      } else {
        this.upstartIntakeDataService
          .submitForm(this.formWizard, submission.data)
          .pipe(take(1))
          .subscribe((programsClaim) => {
            this.store.dispatch(programsClaimsActions.addProgramsClaim({ programsClaim }));
            this.store.dispatch(
              programsClaimsActions.updateSelectedProgramsClaim({ programsClaim }),
            );
            const gtmTag = {
              event: 'custom.newIntakeAddAChild',
            };
            this.googleAnalyticsEnabled$.pipe(take(1)).subscribe((googleAnalyticsEnabled) => {
              if (googleAnalyticsEnabled) {
                this.gtmService.pushTag(gtmTag);
              }
            });
          });
        this.submitted.emit();
      }
    }
  }

  private thereExistsADifferentSubmissionWithSameChildLabel(childLabel: string): boolean {
    const programsClaims = this.editMode
      ? this.programsClaims.filter((programsClaim) => programsClaim.id !== this.selectedClaim.id)
      : this.programsClaims;
    return !isNil(
      programsClaims.find(
        ({ formSubmission }) =>
          childLabel.toLowerCase() === formSubmission.childLabel.toLowerCase(),
      ),
    );
  }

  private initializeData(formWizard, programsClaims, selectedProgramsClaim, language): void {
    this.selectedClaim = selectedProgramsClaim;
    this.programsClaims = programsClaims;
    this.formWizard = formWizard;
    this.isLoading = false;
    this.renderOptions = {
      language: 'en',
      i18n: {
        en: this.formWizard.layout.i18n.en,
      },
      icons: 'waterford-wizard-icons',
    };
    this.formElement.nativeElement.setAttribute('data-form-lang', language);
  }
}
