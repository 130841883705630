import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CodeDialogComponent } from '@app/parent-home/code-dialog/code-dialog.component';
import { Icon } from '@wam/icons';
import { UpstartAccountType } from '../enrollment-wizard-dialog.service';

@Component({
  selector: 'enrollment-type-dialog',
  templateUrl: './enrollment-type-dialog.component.html',
  styleUrls: ['./enrollment-type-dialog.component.scss'],
})
export class EnrollmentTypeDialogComponent {
  protected readonly Icon = Icon;
  protected readonly UpstartAccountType = UpstartAccountType;
  selectedUpstartAccountType: UpstartAccountType;

  constructor(public dialogRef: MatDialogRef<CodeDialogComponent>) {}

  selectUpstartAccountType(upstartAccountType: UpstartAccountType) {
    this.selectedUpstartAccountType = upstartAccountType;
  }

  setUpstartAccountType() {
    this.dialogRef.close(this.selectedUpstartAccountType);
  }

  isSelected(upstartAccountType: UpstartAccountType) {
    return this.selectedUpstartAccountType === upstartAccountType;
  }
}
