import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icon } from '@wam/icons';

@Component({
  selector: 'enrollment-child-dialog',
  templateUrl: './enrollment-child-dialog.component.html',
  styleUrls: ['./enrollment-child-dialog.component.scss'],
})
export class EnrollmentChildDialogComponent {
  Icon = Icon;

  get editMode(): boolean {
    return this.data.editMode;
  }

  constructor(
    private dialogRef: MatDialogRef<EnrollmentChildDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { editMode: boolean },
  ) {}

  closeDialog(dialogResult): void {
    this.dialogRef.close(dialogResult);
  }
}
