"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  key: 'multiple',
  ignore: true
}];
/* eslint-enable max-len */
exports["default"] = _default;