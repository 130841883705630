import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of, switchMap, take } from 'rxjs';
import { EnrollmentChildDialogComponent } from './enrollment-child-dialog/enrollment-child-dialog.component';
import { EnrollmentFacilityDialogComponent } from './enrollment-facility-dialog/enrollment-facility-dialog.component';
import { EnrollmentTypeDialogComponent } from './enrollment-type-dialog/enrollment-type-dialog.component';
import { Store } from '@ngrx/store';
import { State, configurationSelectors } from '@wam/shared';
import { saveUserConfiguration } from '@app/configuration/state/configuration.actions';

export enum UpstartAccountType {
  CARE_CENTER = 'care-center',
  FAMILY = 'family',
}

@Injectable({
  providedIn: 'root',
})
export class EnrollmentWizardDialogService {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store<State>,
  ) {}

  enroll(): void {
    this.tryDetectEnrollmentType();
  }

  openChildDialog(editMode = false): MatDialogRef<EnrollmentChildDialogComponent, boolean> {
    return this.dialog.open(EnrollmentChildDialogComponent, {
      height: 'auto',
      width: 'auto',
      panelClass: ['no-padding-dialog', 'wra-enrollment-form'],
      maxWidth: '540px',
      data: { editMode },
      disableClose: true,
    });
  }

  openFacilityInfoDialog(
    editMode = false,
  ): MatDialogRef<EnrollmentFacilityDialogComponent, boolean> {
    return this.dialog.open(EnrollmentFacilityDialogComponent, {
      height: 'auto',
      width: 'auto',
      panelClass: ['no-padding-dialog', 'wra-enrollment-form'],
      maxWidth: '540px',
      data: { editMode },
      disableClose: true,
    });
  }

  openEnrollmentTypeDialog(): MatDialogRef<EnrollmentTypeDialogComponent, UpstartAccountType> {
    return this.dialog.open(EnrollmentTypeDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '85vh',
      panelClass: 'no-padding-dialog',
    });
  }

  private tryDetectEnrollmentType(): void {
    this.store
      .select(configurationSelectors.getUserConfiguration<UpstartAccountType>('upstartAccountType'))
      .pipe(take(1))
      .subscribe((accountType) => {
        if (!!accountType) {
          this.redirect();
        } else {
          this.openDialog();
        }
      });
  }

  private openDialog(): void {
    this.openEnrollmentTypeDialog()
      .afterClosed()
      .subscribe((enrollmentType) => {
        if (enrollmentType === UpstartAccountType.FAMILY) {
          this.enrollmentFamilyFlow();
        }

        if (enrollmentType === UpstartAccountType.CARE_CENTER) {
          this.enrollmentChildCareFlow();
        }
      });
  }

  private enrollmentFamilyFlow(): void {
    this.saveUpstartAccountType(UpstartAccountType.FAMILY);
    this.openChildDialog()
      .afterClosed()
      .subscribe((needRedirect) => needRedirect && this.redirect());
  }

  private enrollmentChildCareFlow(): void {
    this.openFacilityInfoDialog()
      .afterClosed()
      .pipe(
        switchMap((needRedirect) => {
          if (needRedirect) {
            this.saveUpstartAccountType(UpstartAccountType.CARE_CENTER);
            return this.openChildDialog().afterClosed();
          } else {
            return of(false);
          }
        }),
      )
      .subscribe((needRedirect) => needRedirect && this.redirect());
  }

  private redirect(): void {
    this.router.navigate(['/programs']);
  }

  private saveUpstartAccountType(accountType: UpstartAccountType): void {
    this.store.dispatch(saveUserConfiguration({ key: 'upstartAccountType', value: accountType }));
  }
}
