import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgramsClaimsState } from './programs-claims.state';

const getProgramsClaimsFeatureState = createFeatureSelector<ProgramsClaimsState>('programs-claims');

export const getProgramsClaims = createSelector(
  getProgramsClaimsFeatureState,
  (state) => state.programsClaims,
);

export const getSelectedProgramsClaim = createSelector(
  getProgramsClaimsFeatureState,
  (state) => state.selectedProgramsClaim,
);

export const getIsProgramsClaimsLoading = createSelector(
  getProgramsClaimsFeatureState,
  (state) => state.isProgramsClaimsLoading,
);
