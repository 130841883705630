<div class="enrollment-wizard-dialog">
  <div
    class="enrollment-wizard-dialog__header"
    mat-dialog-title>
    <ng-container *ngIf="!editMode">
      {{ 'Add a child' | translate }}
    </ng-container>
    <ng-container *ngIf="editMode">
      {{ 'Edit child' | translate }}
    </ng-container>
    <cl-functional-button
      (click)="closeDialog(false)"
      [icon]="Icon.cancel"></cl-functional-button>
  </div>

  <enrollment-child-form
    [editMode]="editMode"
    (submitted)="closeDialog(true)"></enrollment-child-form>
</div>
