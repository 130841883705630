<div class="intake_form">
  <div
    *ngIf="isLoading"
    class="intake-form__loading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>

  <div class="intake-form__body">
    <div
      #formElement
      class="waterford-forms"
      id="waterford-mini-form">
      <formio
        *ngIf="!isLoading"
        (ready)="loadFormSubmission()"
        (submit)="submit($event)"
        [form]="formWizard.layout"
        [refresh]="refreshForm"
        [renderOptions]="renderOptions"></formio>
    </div>
  </div>
</div>
