"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  'has-error': 'error',
  'is-invalid': 'error',
  'formio-tab-panel-active': 'active',
  'formio-tab-link-active': 'active',
  'formio-tab-link-container-active': 'active'
};