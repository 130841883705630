import { createAction, props } from '@ngrx/store';
import { FacilityInfo, ProgramsClaim } from './programs-claims.model';

export const loadProgramsClaims = createAction(
  '[Programs Claims Component] Load Form Submissions',
  props<{ formId: string }>(),
);

export const loadProgramsClaimsSuccess = createAction(
  '[Enrollment V2 API] Load Form Submissions Success',
  props<{ programsClaims: ProgramsClaim[] }>(),
);

export const loadProgramsClaimsFail = createAction(
  '[Enrollment V2 API] Load Form Submissions Fail',
  props<{ error: string }>(),
);

export const updateSelectedProgramsClaim = createAction(
  '[Selector Side Effect] Update Selected Submission',
  props<{ programsClaim: ProgramsClaim }>(),
);

export const updateSelectedProgramsClaimData = createAction(
  '[Selector Side Effect] Update Selected Submission Data',
  props<{ programsClaim: ProgramsClaim }>(),
);

export const addProgramsClaim = createAction(
  '[Enrollment V2 API] Add Form Submission',
  props<{ programsClaim: ProgramsClaim }>(),
);

export const loadFacilityInfo = createAction(
  '[Programs Claims Component] Load Facility Info',
  props<{ formId: string }>(),
);

export const loadFacilityInfoSuccess = createAction(
  '[Enrollment V2 API] Load Facility Info Success',
  props<{ facilityInfo: ProgramsClaim<FacilityInfo> }>(),
);

export const loadFacilityInfoFail = createAction(
  '[Enrollment V2 API] Load Facility Info Fail',
  props<{ error: string }>(),
);
