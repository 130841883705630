"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  type: 'checkbox',
  input: true,
  weight: 105,
  key: 'inlineEdit',
  label: 'Inline Editing',
  tooltip: 'Check this if you would like your changes within \'edit\' mode to be committed directly to the submission object as that row is being changed'
}, {
  key: 'defaultValue',
  ignore: true
}, {
  key: 'multiple',
  ignore: true
}];
exports["default"] = _default;