<div class="enrollment-wizard-dialog">
  <div
    class="enrollment-wizard-dialog__header"
    mat-dialog-title>
    <ng-container *ngIf="!editMode">
      {{ 'Add a Facility' | translate }}
    </ng-container>
    <ng-container *ngIf="editMode">
      {{ 'Edit a Facility' | translate }}
    </ng-container>
    <cl-functional-button
      *ngIf="editMode"
      (click)="closeDialog(false)"
      [icon]="Icon.cancel"></cl-functional-button>
  </div>

  <div class="intake_form">
    <div
      *ngIf="isLoading"
      class="intake-form__loading">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <div class="intake-form__body">
      <div
        #formElement
        class="waterford-forms"
        id="waterford-info-form">
        <formio
          (ready)="loadFormSubmission()"
          (submit)="submit($event); closeDialog(true)"
          *ngIf="!isLoading"
          [form]="formWizard.layout"
          [refresh]="refreshForm"
          [renderOptions]="renderOptions"></formio>
      </div>
    </div>
  </div>
</div>
